// import QRCodeStyling from "qr-code-styling";
import { useState, useRef, useEffect, useMemo, useCallback } from "react";
import globalStyles from "../styles/global.module.scss";
import styles from "../styles/qr.module.scss";
import Croper from "./croper"
import axios from "axios";
import { useDispatch } from "react-redux";
import { REQUEST_UPDATE_ACCOUNT } from "../store/account/accountActionType";



interface Qrtype {
  accountId: string;
  showQr: boolean;
  returnStatus: any;
  url: string;
  logo: string;
  color: string;
  token: string;
  isEditable: string;
}
const colorArr = [
  "#AD442F",
  "#FF9E1B",
  "#FEC83C",
  "#3A8476",
  "#01A4B5",
  "#2567B1",
  "#6463B1",
  "#81027C",
  "#D65079",
  "#D3035A",
  "#FFFFFF",
  "#000000",
  "#4C5357",
  "#3C5579",
  "#246062",
  "#5B6150",
  "#41322F",
  "#B99149",
  "#B3906C",
  "#CDBCA8",
];
const Qr: React.FC<Qrtype> = ({ accountId, showQr, returnStatus, url, logo, color, token, isEditable }) => {
  // if (typeof window !== undefined) {
  const [copied, setCopied] = useState<boolean>(false);
  const [image, setImage] = useState<any>(null);
  const [qrColor, setQrColor] = useState<string>("#000000");
  const [qrLogo, setQrLogo] = useState<any>("./qrlogo.png");
  const [cropFile, setCropFile] = useState<any>(null);
  const ref = useRef<any>(null);
  const [qrDownload, setQrDownload] = useState<any>();
  const dispatch = useDispatch();

  const generateQRCode = useMemo(() => {
    //@ts-ignore
    return new QRCodeStyling({
      width: 500,
      height: 500,
      margin: 10,
      data: "https://www.slynk.app/" + url,
      qrOptions: {
        typeNumber: 0,
        mode: "Byte",
        errorCorrectionLevel: "H",
      },
      imageOptions: {
        hideBackgroundDots: true,
        imageSize: 0.4,
        margin: 20,
      },
      dotsOptions: {
        type: "extra-rounded",
        color: qrColor,
      }, // for color middle dots
      backgroundOptions: { color: "#ffffff" },
      image: qrLogo,
      dotsOptionsHelper: {
        colorType: { single: true, gradient: false },
        gradient: {
          linear: true,
          radial: false,
          color1: "#6a1a4c",
          color2: "#6a1a4c",
          rotation: "0",
        },
      },
      // @ts-ignore
      cornersSquareOptions: {
        type: "",
        color: qrColor,
      }, // for color outside square
      cornersSquareOptionsHelper: {
        colorType: { single: true, gradient: false },
        gradient: {
          linear: true,
          radial: false,
          color1: "#000000",
          color2: "#000000",
          rotation: "0",
        },
      },
      // @ts-ignore
      cornersDotOptions: {
        type: "",
        color: qrColor,
      }, // for color inside square
      cornersDotOptionsHelper: {
        colorType: { single: true, gradient: false },
        gradient: {
          linear: true,
          radial: false,
          color1: "#000000",
          color2: "#000000",
          rotation: "0",
        },
      },
      backgroundOptionsHelper: {
        colorType: { single: true, gradient: false },
        gradient: {
          linear: true,
          radial: false,
          color1: "#ffffff",
          color2: "#ffffff",
          rotation: "0",
        },
      },
    });
  }, []);

  useEffect(() => {
    generateQRCode.append(ref.current);
    if (logo) {
      setQrLogo(logo)
    }
    if (color) {
      setQrColor(color)
    }
  }, [logo, color]);

  const onImageChange = (e: any) => {
    e.preventDefault();
    let files;
    let fileSize = e.target.files[0];
    if (fileSize.size > 16384 * 1024) {
      return alert("file size should not more than 2 MB");
    }
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);

    };
    reader.readAsDataURL(files[0]);
  };

  const croppedImage = (data: any) => {

    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => {
      setQrLogo(reader.result);
    };
  };

  const handleQrSave = () => {
    if (!qrLogo.includes("https") && qrLogo !== "./qrlogo.png") {
      let formData = new FormData();
      formData.append("image", cropFile);

      if (formData) {
        try {
          axios({
            url: `${process.env.REACT_APP_BASE_URL}/image`,
            method: "POST",
            data: formData,
            withCredentials: true

          })
            .then((data2: any) => {
              const payload = {
                qrImage: data2.data._id,
                qrColor: qrColor,

              };
              const data = { payload, _id: accountId, token };
              dispatch({ type: REQUEST_UPDATE_ACCOUNT, data });
            })
            .catch((err: any) => {
            });
        } catch (error: any) {
          console.log(error);
          console.log(error.message);
        }
      }
    } else {

      if (qrLogo === "./qrlogo.png") {
        const payload = {
          qrImage: null,
          qrColor: qrColor,
        };
        const data = { payload, _id: accountId, token };
        dispatch({ type: REQUEST_UPDATE_ACCOUNT, data });
      } else if (qrLogo.includes("https")) {
        const payload = {
          qrColor: qrColor,
        };
        const data = { payload, _id: accountId, token };
        dispatch({ type: REQUEST_UPDATE_ACCOUNT, data });
      }
    }
  }

  const croppedFile = async (data: any) => {
    setImage(null);
    setCropFile(data);
  };

  useEffect(() => {

    const qr = generateQRCode;
    setQrDownload(qr);
    qr.update({
      data: "https://www.slynk.app/" + url,
      dotsOptions: { color: qrColor }, // for color middle dots
      image: qrLogo,
      cornersSquareOptions: { color: qrColor }, // for color outside square
      cornersDotOptions: { color: qrColor }, // for color inside square
    });
  }, [qrColor, qrLogo, url, logo, color]);

  const copy = () => {
    navigator.clipboard.writeText("https://www.slynk.app/" + url);
    setCopied(true);
  };

  useEffect(() => {
    if (copied == true) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  const onDownloadClick = () => {
    qrDownload.download({
      extension: "png",
    });
  };

  return (
    <>
      <div className={globalStyles.popUpOuterDiv}>

        {/* <div className="mainSubDiv">
          <div className="mainSubDivSpacing"> */}
            <div className={`${globalStyles.popUp} ${styles.qrPopUp}`}>
              {/* <div
                className={globalStyles.popUpClose}
                onClick={() => returnStatus(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 46.34 46.34"
                >
                  <path
                    className="cls1"
                    d="M42.68,3.66l-39,39m0-39,39,39"
                    style={{
                      fill: "none",
                      strokeLinecap: "round",
                      strokeWidth: "3px",
                    }}
                  ></path>
                </svg>
              </div> */}
              <div className={styles.qrDownload} onClick={onDownloadClick}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 485 485"
                >
                  <g>
                    <g>
                      <path d="M426.5,458h-368C51,458,45,464,45,471.5S51,485,58.5,485h368c7.5,0,13.5-6,13.5-13.5S434,458,426.5,458z" />

                      <path
                        d="M233,378.7c2.5,2.5,6,4,9.5,4s7-1.4,9.5-4l107.5-107.5c5.3-5.3,5.3-13.8,0-19.1c-5.3-5.3-13.8-5.3-19.1,0L256,336.5v-323

       C256,6,250,0,242.5,0S229,6,229,13.5v323l-84.4-84.4c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1L233,378.7z"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div className={styles.qrDiv}>
                {/* {isMobile && !isAndroid ? (
                    <img
                      src="./qrlogo.png"
                      alt=""
                      className={`${styles.qrlogo}`}
                    />
                  ) : null} */}
                <div ref={ref} className={`${styles.qrPopUpImage}`} />
              </div>
              {isEditable === 'true' ?
                <>
                  <div style={{ width: "100%" }}>Qr Color</div>
                  <div className={styles.colorDiv}>
                    {colorArr.map((color, index) => {
                      return (
                        <div
                          className={
                            color == qrColor
                              ? `${styles.colorBox} ${styles.selectedColorBox}`
                              : `${styles.colorBox}`
                          }
                          style={{ background: color }}
                          key={index}
                          onClick={() => setQrColor(color)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.219"
                            height="14.49"
                            viewBox="0 0 20.219 14.49"
                          >
                            <path
                              d="M15803.617-6351.937l5.109,5.108,10.867-10.869"
                              transform="translate(-15801.496 6359.818)"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="3"
                            />
                          </svg>
                        </div>
                      );
                    })}
                  </div>
                  {/* <div style={{ display: "flex" }}>
                    <p onClick={() => setQrLogo("./qrlogo.png")}>slynk </p>

                    <label>
                      Select Image
                      <input
                        type="file"
                        accept="image/*"
                        id="qqqq"
                        hidden
                        onChange={(event: any) => onImageChange(event)}
                        onClick={(event: any) => {
                          event.target.value = null;
                        }}
                      />
                    </label>
                  </div>

                  <div><button onClick={handleQrSave}>save</button></div> */}
                  <div className={globalStyles.twoButtonDiv}>
                    <label
                      className={`${globalStyles.homeButton} ${globalStyles.buttonUnFilled}`}
                    >
                      Select Image
                      <input
                        type="file"
                        accept="image/*"
                        id="qqqq"
                        hidden
                        onChange={(event: any) => onImageChange(event)}
                        onClick={(event: any) => {
                          event.target.value = null;
                        }}
                      />
                    </label>
                    <button
                      className={`${globalStyles.homeButton} ${globalStyles.buttonFilled}`}
                      onClick={handleQrSave}
                    >
                      save
                    </button>
                  </div>
                </> :
                null
              }
              {copied ? (
                <p
                  className={`${styles.qrPopUpLink}`}
                  style={{ marginLeft: "180px", color: "#3A8476" }}
                >
                  link copied
                </p>
              ) : null}

              <div className={`${styles.qrPopUpLinkDiv}`}>
                <p className={`${styles.qrPopUpLink}`}>www.slynk.app/{url}</p>
                <div className={`${styles.qrPopUpLinkIconDiv}`}>
                  <div
                    className={`${styles.qrPopUpLinkIcon}`}
                    onClick={() => {
                      copy();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18.139"
                      height="21"
                      viewBox="0 0 18.139 21"
                    >
                      <path
                        d="M373.634-347.811v1.905h-11.46v13.349h-1.912v-.236c0-4.318.009-8.637-.007-12.955a1.968,1.968,0,0,1,1.658-2.041c.012,0,.022-.015.032-.023Zm4.759,5.816a1.909,1.909,0,0,0-1.961-2q-5.2-.011-10.4,0a1.9,1.9,0,0,0-1.954,1.963q-.007,6.623,0,13.247a1.91,1.91,0,0,0,1.972,1.967q5.188.007,10.376-.007a2.119,2.119,0,0,0,.868-.193,1.843,1.843,0,0,0,1.1-1.8q0-3.291,0-6.583T378.392-341.994Zm-1.92-.076v13.335H366v-13.335Z"
                        transform="translate(-360.255 347.811)"
                        fill="#b4b4b4"
                      />
                    </svg>
                  </div>
                  <div
                    className={`${styles.qrPopUpLinkIcon}`}
                    onClick={() =>
                      navigator.share({ url: "https://www.slynk.app/" + url })
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19.253"
                      height="21"
                      viewBox="0 0 19.253 21"
                    >
                      <path
                        d="M20.925,24.773a8.127,8.127,0,0,1-1.016-.295,3.47,3.47,0,0,1-2.046-3.884.185.185,0,0,0-.112-.237q-3.054-1.735-6.1-3.479a.228.228,0,0,0-.317.032,3.494,3.494,0,0,1-5.779-2.894,3.347,3.347,0,0,1,2.157-2.97,3.364,3.364,0,0,1,3.623.585c.089.074.151.13.28.056,2.085-1.2,4.173-2.384,6.244-3.565-.019-.351-.061-.69-.051-1.028a3.491,3.491,0,0,1,2.963-3.284,1.337,1.337,0,0,0,.156-.039h.739a.8.8,0,0,0,.115.036A3.5,3.5,0,1,1,18.866,9.79c-.048-.046-.1-.09-.152-.139l-6.323,3.6a3.58,3.58,0,0,1,0,2.039l6.356,3.622a1.316,1.316,0,0,1,.133-.172,3.422,3.422,0,0,1,2.921-.931,3.5,3.5,0,0,1,.376,6.845c-.17.045-.344.079-.515.117Z"
                        transform="translate(-5.539 -3.773)"
                        fill="#b4b4b4"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        {/* </div>


</div> */}
        {/* <button onClick={onDownloadClick}>Download</button> */}
      {image ? (
        <Croper
          imageForCrop={image}
          croppedImage={croppedImage}
          croppedFile={croppedFile}
          ratio={1}
          closeCropper={(data: any) => setImage(data)}
        />
      ) : null}
    </>
  );
};

export default Qr;
