import Cropper from "react-cropper";
import React, { useEffect, useState } from "react";
import "cropperjs/dist/cropper.css";
import globalStyles from "../styles/global.module.scss";
interface respons {
  type: any;
  data: any;
}
interface ImageCrop {
  imageForCrop: any;
  croppedImage: any;
  croppedFile: any;
  closeCropper: any;
  ratio: any;
}
const Croper: React.FC<ImageCrop> = ({
  imageForCrop,
  croppedImage,
  croppedFile,
  closeCropper,
  ratio,
}) => {
  const [image, setImage] = useState<any>(imageForCrop);
  const [cropData, setCropData] = useState<any>(null);
  const [cropper, setCropper] = useState<any>(null);
  const [imageFile, setImageFile] = useState<any>(null);
  useEffect(() => {
    // setCropData(null)
    // setCropper(null)
  }, [image]);
  const getCropData = async () => {
    if (cropper) {
      setImage(null);
      cropper.getCroppedCanvas().toBlob((blob: any) => {
        const file = new File([blob], "my_image.png", {
          type: "image/png",
          lastModified: new Date().getTime(),
        });
        setCropData(file);
        croppedImage(file);
        setImageFile(file);
        croppedFile(file);
      });

      // cropper.getCroppedCanvas().toBlob(function (blob: any) {
      //     const file: any = new File([blob], "my_image.png", { type: "image/png", lastModified: new Date().getTime() })
      //     setImageFile(file)
      //     croppedFile(file)
      // })
    }

    // if (typeof cropper !== "undefined") {
    //     setImage(null)
    //     setCropData(cropper.getCroppedCanvas().toDataURL());
    //     croppedImage(cropper.getCroppedCanvas().toDataURL());
    //     let matches = await cropper
    //         .getCroppedCanvas()
    //         .toDataURL()
    //         .match(/^data:([A-Za-z-+\/]+);base64,(.+)$/);
    //     let response: respons = {
    //         type: "",
    //         data: "",
    //     };

    //     if (matches.length !== 3) {
    //         return new Error("Invalid input string");
    //     }
    //     response.type = matches[1];
    //     response.data = Buffer.from(matches[2], "base64");
    //     let decodedImg = response;
    //     let imageBuffer = decodedImg.data;
    //     // let type = decodedImg.type;
    //     let arr = cropper.getCroppedCanvas().toDataURL().split(",");
    //     let extension = arr[0].match(/:(.*?);/)[1];
    //     let fileName =
    //         `undefined_` + new Date().getTime() + "demo" + `.` + extension;

    //     const fil = await new File([imageBuffer], fileName, {
    //         type: "image/png",
    //     });
    //     console.log("file is", fil)
    //     setImageFile(fil)
    //     croppedFile(fil)
    // }
  };
  return (
    <>
      <div className={globalStyles.popUpOuterDiv}>
        {/* <div className="mainSubDiv">
          <div className="mainSubDivSpacing"> */}
            <div
              className={`${globalStyles.popUp}`}
              style={{ paddingTop: "35px" }}
            >
              {/* <div
                className={globalStyles.popUpClose}
                style={{ top: "15px" }}
                onClick={() => closeCropper(null)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 46.34 46.34"
                >
                  <path
                    className="cls1"
                    d="M42.68,3.66l-39,39m0-39,39,39"
                    style={{
                      fill: "none",
                      strokeLinecap: "round",
                      strokeWidth: "3px",
                    }}
                  ></path>
                </svg>
              </div> */}
              <Cropper
                style={{ height: '85%', width: "100%" }}
                initialAspectRatio={0.8}
                aspectRatio={ratio ? ratio : 0.8}
                preview=".img-preview"
                src={image}
                dragMode="move"
                viewMode={3}
                guides={false}
                center={false}
                highlight={false}
                cropBoxMovable={false}
                cropBoxResizable={false}
                toggleDragModeOnDblclick={false}
                restore={false}
                responsive={true}
                autoCropArea={1}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
              />
              <button
                className={`${globalStyles.homeButton} ${globalStyles.buttonFilled} ${globalStyles.centerButton} ${globalStyles.cropButton} `}
                onClick={getCropData}
              >
                Crop Image
              </button>
            </div>
          </div>
        {/* </div>
      </div> */}
    </>
  );
};

export default Croper;
