import axios from "axios";
import {
  all,
  put,
  takeLatest,
  call,
  takeEvery,
  delay,
} from "redux-saga/effects";
import {
  REQUEST_ACCOUNT,
  SET_ACCOUNT,
  REQUEST_UPDATE_ACCOUNT,
  SUCCESS_UPDATE_ACCOUNT,
  ERROR_UPDATE_ACCOUNT,
} from "./accountActionType";

function* requestAccount(action: Record<string, any>): any {
  try {
    console.log(action);
    const result: any = yield call(getAccount, action.accountId, action.token);

    yield put({ type: SET_ACCOUNT, payload: result.data });
  } catch (err) {
    console.error(`Error fetching switched account`, action);
  }
}
function* requestUpdateAccount(action: Record<string, any>): any {
  try {
    const fetchAccount = action.data.fetchAccount;
    delete action.data.fetchAccount;
    const result: any = yield call(
      updateAccount,
      action.data.payload,
      action.data._id,
      action.data.token
    );

    yield put({ type: SUCCESS_UPDATE_ACCOUNT, payload: result.data });

    // if (fetchAccount) {
    //   put({ type: REQUEST_USER })
    // }

    action && action.onSuccess && action.onSuccess();
  } catch (error: any) {
    console.log(error);
    let message = error.response.data.message;

    yield put({ type: ERROR_UPDATE_ACCOUNT, payload: message });
  }
}

export function updateAccount(
  payload: Record<string, any>,
  _id: string,
  token: string
) {
  if (token) {
    return axios({
      method: "PATCH",
      url: `${process.env.REACT_APP_BASE_URL}/account/${_id}`,
      data: payload,
      withCredentials: !token,
      headers: { authorization: token },
    });
  } else {
    return axios({
      method: "PATCH",
      url: `${process.env.REACT_APP_BASE_URL}/account/${_id}`,
      data: payload,
      withCredentials: true,
    });
  }
}

export function getAccount(_id: string, token: string) {
  if (token) {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}/account/qrcode/${_id}`,
      // data: payload,
      withCredentials: !token,
      headers: { authorization: token },
    });
  } else {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}/account/qrcode/${_id}`,
      // data: payload,
      withCredentials: true,
    });
  }
}

const accountSaga = function* () {
  yield all([
    takeLatest(REQUEST_ACCOUNT, requestAccount),
    takeLatest(REQUEST_UPDATE_ACCOUNT, requestUpdateAccount),

    // takeLatest(_ACCOUNT, requestAccount),
  ]);
};

export default accountSaga;
