import { useSelector } from "react-redux";
import {
  REQUEST_ACCOUNT,
  SET_ACCOUNT,
  REQUEST_UPDATE_ACCOUNT,
  SUCCESS_UPDATE_ACCOUNT,
  ERROR_UPDATE_ACCOUNT,
} from "./accountActionType";

const initialState: {
  busy: boolean;
  message: string;
  account:{};
} = {
  busy: false,
  message: "",
  account: {},
};

const account = (state = initialState, action: Record<string, any>) => {
  switch (action.type) {
    case REQUEST_ACCOUNT:
    case REQUEST_UPDATE_ACCOUNT:  
      return {
        ...state,
        message: "",
        busy: true,
      };

    case SET_ACCOUNT:
    case SUCCESS_UPDATE_ACCOUNT:
      return {
        ...state,
        busy: false,
        message: "",
        account: action.payload,
      };
   
    case ERROR_UPDATE_ACCOUNT:
      return {
        ...state,
        busy: false,
        message:
          action.payload || "Something happened wrong try again after sometime",
      };
    default:
      return state;
  }
};

export default account;

export function useAccount() {
  return useSelector((state: Record<string, any>) => state.account);
}
