import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Qr from './Component/qr';
import { REQUEST_ACCOUNT, SET_ACCOUNT } from './store/account/accountActionType';
import { useAccount } from './store/account/reducer';
// import { useUser } from './store/user/reducer';
// import { REQUEST_USER } from './store/user/userActionType';
import { Route, useParams, useSearchParams } from "react-router-dom";



const App = () => {
  const [qrPopup, setQrPopup] = useState(false)
  const [params, setParams] = useState<any>()
  // const { user, busy: userBusy, message: userMassage }: any = useUser();
  const { account, busy: accountBusy, message: accountMassage } = useAccount();
  const dispatch = useDispatch();

  // console.log = function () { };
  // console.error = function () { };
  // console.warn = function () { };

  useEffect(() => {


    var params = Object.fromEntries(new URLSearchParams(window.location.search))
    setParams(params)
    let script = document.querySelector(`script[src="https://qrcode.slynk.app/qrStyle.js"]`);
    // let script = document.querySelector(`script[src="http://localhost:3000/qrStyle.js"]`);
    const handleScript = (e: any) => {
      setQrPopup(true)
    };

    if (!script) {
      script = document.createElement("script");
      //@ts-ignore
      script.type = "application/javascript";
      //@ts-ignore
      script.src = "https://qrcode.slynk.app/qrStyle.js";
      // script.src = "http://localhost:3000/qrStyle.js";
      //@ts-ignore
      script.async = true;
      document.body.appendChild(script);
      script.addEventListener("load", handleScript);
      script.addEventListener("error", handleScript);
    }
    script.addEventListener("load", handleScript);
    script.addEventListener("error", handleScript);

    return () => {
      //@ts-ignore      
      script.removeEventListener("load", handleScript);
      //@ts-ignore
      script.removeEventListener("error", handleScript);
    };
  }, [])

  useEffect(() => {

  }, [])

  // useEffect(() => {
  //   if (Object.keys(user).length !== 0 && params.accountId) {
  //     const account: any = user.accounts.find((o: any) => o._id.toString() === params.accountId);
  //     dispatch({ type: SET_ACCOUNT, payload: account });
  //   }
  // }, [user])

  console.log(account, Object.keys(account).length)
  useEffect(() => {
    if (params) {
      if (params.token) {
        console.log("in", Object.keys(account).length == 0 && !accountBusy, account)
        const oldToken = params.token
        const paramsToken = oldToken.replaceAll('!@', "+");
        if (
          (Object.keys(account).length == 0 && !accountBusy)
        ) {

          dispatch({ type: REQUEST_ACCOUNT, token: paramsToken, accountId: params.accountId });
        }
      }
    }

  }, [params])

  return (
    <>

      {qrPopup ?
        Object.keys(account).length !== 0 ?
          <Qr
            accountId={account._id}
            showQr={qrPopup}
            url={account.accountName}
            returnStatus={(data: boolean) => setQrPopup(data)}
            logo={account.qrImage?.url}
            color={account.qrColor}
            token={params.token.replaceAll('!@', "+")}
            isEditable={params.isEditable}
          /> : null
        : null}

    </>
  )
}

export default App;
