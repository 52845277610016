
import App from './App';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import initStore from "./store/store";
import "./index.scss"
const store = initStore();
//@ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Provider store={store}>
    <App />
</Provider>)