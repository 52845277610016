import { all, fork } from "redux-saga/effects";
import accountSaga from "./account/saga";
// import userSaga from "./user/saga";

export default function* rootSaga() {
  yield all([
    // fork(userSaga),
    fork(accountSaga)
  ]);
}
